@layer modules, ui, base;
@layer base {
  .page_goBack__4qpha {
  align-self: flex-start;
}

.page_main__Hrev4 {
  display: flex;
  flex-direction: column;
}

.page_aside__RammA {
  display: none;
}

.page_title__7a11K {
  margin-bottom: var(--sk-space-16);
  padding: var(--sk-space-24) var(--sk-space-16);
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
}

.page_moduleSection__pmMKj {
  padding: var(--sk-space-32) var(--sk-space-16);
  background-color: var(--sk-color-white);
}

.page_unitHeader__eN_WU {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: var(--sk-space-24);
  border-bottom: thin solid var(--sk-color-grey-200);
  gap: var(--sk-space-8);
}

.page_stepper__hjymw {
  display: flex;
  gap: var(--sk-space-8);
}

.page_stepperItem__PuoD1 {
  width: 20px;
  height: 20px;
  color: var(--sk-color-grey-500);
}

.page_stepperItemIcon__uWv4W {
  width: 20px;
  height: 20px;
  color: var(--sk-color-black);
}

.page_unitNavigation__0_Onu {
  display: flex;
  justify-content: space-between;
  margin-top: var(--sk-space-64);
  gap: var(--sk-space-40);
}

.page_unitCompletion__udE6_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sk-space-8);
  justify-content: center;
  margin-top: var(--sk-space-64);
}

.page_completionQuizzTip__EQ2iu {
  display: flex;
  color: var(--sk-color-warning-500);
  text-align: center;
  gap: var(--sk-space-4);
}

@media (min-width: 768px) {
  .page_title__7a11K {
    padding: var(--sk-space-32) var(--sk-space-40);
    border-radius: var(--sk-radius-16);
  }

  .page_moduleSection__pmMKj {
    padding: var(--sk-space-32) var(--sk-space-72);
    border: thin solid var(--sk-color-grey-100);
    border-radius: var(--sk-radius-16);
  }

  .page_unitHeader__eN_WU {
    flex-direction: row-reverse;
    align-items: center;
  }
}

@media (min-width: 1312px) {
  .page_main__Hrev4 {
    flex-direction: row;
    gap: var(--sk-space-8);
  }

  .page_content__tKO92 {
    flex: 1;
    width: calc(100vw - 496px);
  }

  /* Aside Begin */

  .page_aside__RammA {
    display: block;
    width: 312px;
    padding: var(--sk-space-24);
  }

  .page_asideHeader__mC0yx {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--sk-space-16);
    gap: var(--sk-space-24);
  }

  .page_asideProgressBar__MlfXn {
    width: 100%;
    height: var(--sk-space-4);
    margin-bottom: var(--sk-space-40);
    border-radius: var(--sk-radius-12);
    background-color: var(--sk-color-grey-100);
  }

  .page_asideProgress__WiMEt {
    height: 100%;
    background-color: var(--sk-color-black);
  }

  .page_sequencesList__ip4Zs {
    display: flex;
    flex-direction: column;
    gap: var(--sk-space-20);
    padding: 0;
    list-style-type: none;
  }

  .page_sequenceItemLink__x17VM {
    display: flex;
    gap: var(--sk-space-8);
    color: var(--sk-color-black);
    font-size: var(--sk-typography-body-2-regular-font-size);
    text-decoration: none;
  }

  .page_sequenceItemLinkComplete___GPyu {
    color: var(--sk-color-grey-600);
  }

  .page_sequenceItemLinkCurrent__dG_S1 {
    color: var(--sk-color-purple-500);
    font-weight: var(--sk-typography-body-2-semibold-font-weight);
  }

  .page_sequenceItemLinkDefault__vxUst {
    margin-left: calc(var(--sk-space-24) + var(--sk-space-8));
  }

  .page_sequenceItemTitle__4ODAh {
    flex: 1;
  }

  /* Aside End */
}

}
@layer ui {
  /** @define Spinner */

.Spinner_jds-Spinner__qSvCy {
  width: 1.25rem;
  height: 1.25rem;
  animation: Spinner_spinner__z_cy6 2s linear infinite;
  stroke-width: 0.6rem;
  color: currentcolor;
  vertical-align: middle;
}

.Spinner_jds-Spinner--minor__v4Zv_ {
  width: 1rem;
  height: 1rem;
}

.Spinner_jds-Spinner--ghost__g_jYG {
  color: var(--color-black);
}

@keyframes Spinner_spinner__z_cy6 {
  50% {
    transform: rotate(360deg);
    stroke-dashoffset: 20;
  }

  100% {
    transform: rotate(1080deg);
    stroke-dashoffset: 243.4675;
  }
}

}
@layer base {
  /* stylelint-disable plugin/selector-bem-pattern */
/** @define Loading */

.Loading_sha-Loading__tmLTe {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
}

.Loading_sha-Loading__spinner--isOnWhiteBackground__pHKQ_ {
  stop-color: var(--mainColor);
}

}
@layer base {
  .LoadingSection_main__DmYGj {
  display: flex;
  flex: 1;
}

}
@layer base {
  .UnitIframe_main__lq8Ck {
  display: block;
  /* Override the main html element in iframe with padding */
  width: calc(100% + 20px);
  /* Override the main html element in iframe with padding */
  margin: 0 -20px;
  border: none;
}

.UnitIframe_mainLoading__q9BKY {
  display: none;
}

.UnitIframe_loader__2Oxlp {
  height: 100vh;
}

@media (min-width: 64rem) {
  /* Override the main html element in iframe with padding */
  .UnitIframe_main__lq8Ck {
    width: calc(100% + 40px);
  }
}

}
@layer base {
  /** @define Error */
/* stylelint-disable plugin/selector-bem-pattern */

.StandardError_wrapper___WScW {
  box-sizing: border-box;
  min-height: 100vh;
  padding: 3em;
}

.StandardError_main__o_GST {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  padding: var(--sk-space-32);
  border: 2px solid var(--sk-color-black);
  border-radius: var(--sk-space-8);
}

.StandardError_content__wvNn4 {
  display: flex;
  align-items: center;
  margin-bottom: var(--sk-space-32);
}

.StandardError_illustration__itwwh {
  align-self: center;
  width: 100%;
  max-width: 300px;
}

.StandardError_illustration__itwwh img {
  width: 100%;
  height: auto;
}

.StandardError_errorId__303Kq {
  font-style: italic;
  text-align: right;
}

@media (min-width: 48rem) {
  .StandardError_main__o_GST {
    flex-direction: row;
  }

  .StandardError_content__wvNn4 {
    flex: 3 1 0;
    margin-right: var(--sk-space-32);
    margin-bottom: 0;
  }

  .StandardError_illustration__itwwh img {
    flex: 2 1 0;
    width: auto;
  }

  .StandardError_errorId__303Kq {
    position: absolute;
    right: 1rem;
    bottom: 0.5rem;
  }
}

@media (min-width: 64rem) {
  .StandardError_main__o_GST {
    max-width: 75%;
    margin: 0 auto;
  }
}

}
@layer base {
  .page_goBack__VTaVh {
  align-self: flex-start;
}

.page_main__cqqMX {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-40);
  padding: 0 var(--sk-space-16);
}

.page_blockButton___iWVW {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .page_main__cqqMX {
    padding: 0;
  }

  .page_blockButton___iWVW {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

}
@layer base {
  .ApplicationFlow_main__YiWlg {
  --form-transform: translateX(0);

  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--sk-color-white);
}

.ApplicationFlow_header__CwaJU {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: var(--sk-space-24) var(--sk-space-24) 0;
}

.ApplicationFlow_isLoading__t1eWc {
  pointer-events: none;
}

.ApplicationFlow_closeButton__pz_2_ {
  margin-left: auto;
}

.ApplicationFlow_content__4Utq2 {
  flex-grow: 1; /* Take all the remaining height */
  padding: var(--sk-space-24) var(--sk-space-24) 0;
  overflow: hidden auto; /* Make vertical content scrollable */
}

/**
 * The layout make twice the width of the container and translate it to the left.
 */
.ApplicationFlow_content--twoCols__xgmDz {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr;
  width: 200%;
  transform: var(--form-transform);
  transition: transform 300ms ease-in-out;
  gap: var(--sk-space-48);
}

.ApplicationFlow_footer__Aup2i {
  padding: var(--sk-space-24);
  background-color: var(--sk-color-white);
  /* shadow is --sk-color-grey-600 with 15% opacity */
  box-shadow: 0 var(--sk-space-4) var(--sk-space-16) rgb(100 96 108 / 15%);
}

.ApplicationFlow_button__1QnWh {
  width: 100%;
}

.ApplicationFlow_form__cGY_S {
  height: -moz-fit-content;
  height: fit-content;
}

.ApplicationFlow_form--hidden__DpDh8 {
  visibility: hidden;
  pointer-events: none;
}

@media (min-width: 1312px) {
  .ApplicationFlow_main__YiWlg {
    position: sticky;
    height: calc(100vh - var(--distance-to-top, 0) - var(--sk-space-24) * 2);
    margin: var(--sk-space-24) 0;
    overflow: hidden auto;
    transition: height 0.2s;
    border-radius: var(--sk-radius-24);
    box-shadow: 0 var(--sk-space-2) var(--sk-space-24) 0 rgb(0 0 0 / 16%);
    inset: var(--sk-space-24) 0;
  }

  .ApplicationFlow_heading___success__3s5yQ {
    display: none;
  }
}

}
@layer base {
  .UploadFile_container__TsZsF {
  display: none;
}

.UploadFile_container___visible__pDrwM {
  display: block;
}

.UploadFile_main__5MeOi {
  display: none;
  position: relative;
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-050);
  text-align: center;
}

.UploadFile_main___visible__7q9kV {
  display: block;
}

.UploadFile_main___dragOver__15kn8 {
  background-color: var(--sk-color-grey-100);
}

.UploadFile_overtitle__HJOjo {
  margin-bottom: var(--sk-space-16);
}

.UploadFile_icon__OuSn0 {
  margin-bottom: var(--sk-space-24);
  background-color: var(--sk-color-grey-100);
}

.UploadFile_fileInfo__N32ex {
  margin-top: var(--sk-space-24);
  color: var(--sk-color-grey-500);
}

.UploadFile_description__fPb2M {
  margin-top: var(--sk-space-4);
}

.UploadFile_uploadButton__c_ODS {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
  width: 100%;
  margin-top: var(--sk-space-16);
}

/* This prevents to drop a file on the button */
.UploadFile_main___dragOver__15kn8 .UploadFile_uploadButton__c_ODS {
  z-index: var(--z-reset-in-the-current-stacking-context);
}

/* The input needs to be above the whole component to accept dropped files */
.UploadFile_uploadButton__input__0icHO {
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.UploadFile_error__WgfNl {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
}

}
@layer base {
  .CvStep_header__sXUxx {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  padding: var(--sk-space-40) 0;
  text-align: center;
}

.CvStep_content__Vzu3U {
  padding-bottom: var(--sk-space-40);
}

.CvStep_footer___HDQF {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--sk-space-24);
}

.CvStep_footer__button__6rf_8 {
  flex: 1 1 auto;
}

.CvStep_footer___HDQF input[type="file"] {
  display: none;
}

@media (max-width: 767px) {
  .CvStep_footer___HDQF {
    display: flex;
    position: absolute;
    right: var(--sk-space-16);
    bottom: var(--sk-space-40);
    left: var(--sk-space-16);
    flex-direction: column;
    gap: var(--sk-space-16);
  }
}

.CvStep_cvHelpText__qquA1 {
  margin-top: var(--sk-space-16); 
  color: var(--sk-color-grey-600);
}

}
@layer base {
  .CvBox_clickableCard___h8je {
  border-color: transparent !important;
}

.CvBox_uploadButton__WnLc3 {
  width: auto;
}

.CvBox_footer__QRbn6 {
  display: flex;
  justify-content: center;
  margin-top: var(--sk-space-16);
}

.CvBox_footer__QRbn6 input[type="file"] {
  display: none;
}

}
@layer base {
  .CoverLetterContent_messageField__xbRht {
  box-sizing: border-box;
  width: 100%;
  padding: var(--sk-space-12) var(--sk-space-4) var(--sk-space-12) var(--sk-space-16);
  border: 1px solid var(--sk-color-grey-300);
  border-radius: var(--sk-radius-8);
  font-family: var(--sk-font-families-poppins);
  font-size: var(--sk-font-size-2);
  resize: vertical;
}

.CoverLetterContent_messageField__xbRht:focus {
  outline: 2px solid var(--sk-color-grey-500);
  outline-offset: -1px;
}

.CoverLetterContent_messageLabel__NdxVy {
  margin: var(--sk-space-16) 0 var(--sk-space-4);
}

.CoverLetterContent_messageLength__7kB5u {
  color: var(--sk-color-grey-500);
  text-align: end;
}

.CoverLetterContent_error__fV6op {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
}

.CoverLetterContent_warning__pQZaE {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-warning-500);
}

}
